/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.24.1/plugins/line-numbers/prism-line-numbers.min.css
 * - /npm/prismjs@1.24.1/plugins/toolbar/prism-toolbar.min.css
 * - /npm/prismjs@1.24.1/plugins/match-braces/prism-match-braces.min.css
 * - /npm/prism-themes@1.8.0/themes/prism-nord.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
